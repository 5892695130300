import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import EmailCapture from "../components/emailCatpure"
// import EmailRedirect from "../components/emailFormRedirect"

const ProcessPage = () => (
  <Layout>
    <SEO title="How it works" />

    {/* HERO */}
    <div className="hero1">
      <div className="content taCenter">
        <h1>How it works</h1>
        {/* <p>A Fully Managed SMS Marketing Service.</p> */}
        {/* <p>
          Want to broadacst a text message to your customers?
          <br /> Just contact us and we take care of the rest!
        </p> */}
        {/* <hr /> */}
        {/* <p>A Fully Managed SMS Marketing Service</p> */}
        {/* <p>
          Have a text message you want to broadcast out?
          <br /> Just contact us and we take care of the rest!
        </p> */}
        {/* <p>
          Want to broadacst a text message to your customers?
          <br /> Just contact us and we take care of the rest!
        </p> */}
        <p>
          Simple and Hassle Free Service{" "}
          <span role="img" aria-label="sunglasses-smile">
            😎
          </span>
        </p>
        {/* <hr />
                <p>Contact us and we'll handle it for you!</p>
                <p>
                  <input
                    type="email"
                    name=""
                    placeholder="your.name@email.com"
                  />
                  <br />
                  <button className="">Get Started</button>
                  <button className="">Yes, Contact Me</button>
                </p> */}
      </div>
    </div>

    {/* HOW IT WORKS */}
    <div className="hero4">
      <div className="content">
        {/* <h1>How it Works</h1>
        <br /> */}
        <div className="flexContainer">
          <div className="card desktopIcon">
            <p>
              <i className="material-icons">mail</i>
            </p>
          </div>
          <div className="card">
            <p className="mobileIcon">
              <i className="material-icons">mail</i>
            </p>
            <h2>1. Contact Us</h2>
            <p>
              The first step is to fill out our email contact form. We'll get in
              touch with you to get started.
            </p>
            {/* <p>
            The first step is to fill out our email contact form. We'll get in
            touch with you to get started and answer any additional questions
            you may have.
          </p> */}
          </div>
          <div className="card">
            <p className=" mobileIcon">
              <i className="material-icons">group</i>
            </p>
            <h2>2. Share Your Contact List</h2>
            <p>
              Next, you just send us your contact list with the mobile phone
              numbers that you want to reach.
            </p>
          </div>
          <div className="card desktopIcon">
            <p>
              <i className="material-icons">group</i>
            </p>
          </div>
          <div className="card desktopIcon">
            <p>
              <i className="material-icons">chat</i>
            </p>
          </div>
          <div className="card">
            <p className="mobileIcon">
              {/* <i className="material-icons">record_voice_over</i> */}
              <i className="material-icons">chat</i>
            </p>
            <h2>3. Send Us Your Message</h2>
            {/* <p>
            We'll contact you each month to ask what message you'd like sent
            out. We also have message templates to help in case you get writer's
            block!
          </p> */}
            {/* <p>
            We'll contact you each month to ask if there's a message you'd like
            sent out. We even share message samples to give you ideas.
          </p> */}
            {/* <p>
              We'll contact you each month to ask if there's a message you'd
              like sent out. We even share{" "}
              <a href="https://samples.vantagesms.com">message samples</a> in
              case you get writer's block!
            </p> */}
            <p>
              We'll contact you each month to ask if there's a message you'd
              like sent out.
            </p>
          </div>
          <div className="card">
            <p className="mobileIcon">
              <i className="material-icons">thumb_up</i>
              {/* <i className="material-icons">beenhere</i> */}
            </p>
            <h2>4. That's It!</h2>
            {/* <p>
            You'll be emailed a report about 1 week after a broadcast that
            outlines delivery statistics.
            <br />
            <br />
            Any 2-way (inbound) messages you get will be forwarded to your email
            where you can respond.
          </p> */}
            <p>
              You'll be emailed a report and invoice about 1 week after your
              broadcast that outlines delivery statistics. Any inbound messages
              you receive are forwarded to your email.
            </p>
            {/* <p>
            You'll be emailed a report about 1 week after a broadcast that
            outlines delivery statistics. Any inbound messages you get will be
            forwarded to your email where you can respond.
          </p> */}
          </div>
          <div className="card desktopIcon">
            <p>
              <i className="material-icons">thumb_up</i>
              {/* <i className="material-icons">beenhere</i> */}
            </p>
          </div>
        </div>
      </div>
    </div>

    {/* FOOTER CTA */}
    {/* HERO */}
    <div className="heroFooter taCenter">
      <div className="content">
        <h1>Grow Your Sales </h1>
        {/* <p>
          Share your contact lists. Collaborate on message. We take care of the
          rest!
        </p> */}
        <p>Reach the customers that matter most, your own.</p>
        <EmailCapture />
        {/* <p style={{ padding: "0px 20px" }}>
          <input type="email" name="" placeholder="your.name@email.com" />
          <br />
          <button className="">Get Started</button>
          <button className="">Yes, Contact Me</button>
          <button className="">Yes, Contact Me to Get Started</button>
        </p> */}
      </div>
    </div>
  </Layout>
)

export default ProcessPage
